<template>
  <div class="boss">
    <div class="bgc">
      <div class="content">
        <div class="header">
          {{ name }}
        </div>
        <div
          class="welfareText"
          :class="{ notrue: isTrue == '0' }"
          v-html="xieyi"
        ></div>
        <div class="btn" v-if="isTrue == '1'">
          <div class="disagree" @click="disagree">退出</div>
          <div class="agree" @click="agree">
            <img src="../../assets/img/tyy.png" alt="" />
          </div>
        </div>
      </div>
      <div class="close" v-if="isTrue == '0'" @click="pageClose">
        <img src="../../assets/img/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { WebView } from "../../common/app";
import { getXieyi } from "../../common/api";
export default {
  data() {
    return {
      type: "",
      xieyi: "",
      name: "",
      isTrue: "",
    };
  },
  created() {
    WebView.getProtocol = this.getProtocol;
  },
  methods: {
    getProtocol(types) {
      let all = types.split("And");
      this.type = all[0];
      this.isTrue = all[1];
      this.Xieyi();
    },
    async Xieyi() {
      let res = await getXieyi({ type: this.type });
      if (res.data.code != 200) return;
      this.xieyi = res.data.data.content;
      this.name = res.data.data.name;
    },
    disagree() {
      window.location.href = "uniwebview://disagree?test=disagree";
    },
    agree() {
      window.location.href = "uniwebview://agree?test=agree";
    },
    pageClose() {
      window.location.href = "uniwebview://quit?test=quit";
    },
  },
};
</script>

<style lang="scss" scoped>
.boss {
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#000, 0.1);
  .bgc {
    width: 7.5rem;
    height: 10.5rem;
    display: flex;
    justify-content: center;
    margin: 0.4rem;
    position: relative;
  }
  .content {
    width: 7.5rem;
    height: 10.4rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.2rem;
    overflow: hidden;
    background: #fbe5ba;
    .header {
      height: 1.4rem;
      width: 100%;
      background: #d79f6d;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.6rem;
      color: #fff;
    }
  }
  .welfareText {
    width: 7.5rem;
    height: 7.6rem;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0.1rem;
    box-sizing: border-box;
  }
  .notrue {
    height: 8.8rem;
    overflow: hidden;
    overflow-y: scroll;
  }
  .btn {
    width: 7.5rem;
    height: 1.4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .disagree {
      width: 1.3rem;
      height: 0.8rem;
      padding: 0 0.5rem;
      color: #945614;
      border: 1px solid #945614;
      border-radius: 2rem;
      line-height: 0.9rem;
      text-align: center;
    }
    .agree {
      height: 1.4rem;
      display: flex;
      align-items: center;
      img {
        width: auto;
        height: 0.9rem;
      }
    }
  }
}
.close {
  position: absolute;
  top: -0.6rem;
  left: 7.1rem;
  img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }
}
</style>
